import { useAuthStore } from '@/store/auth.store';

export const fetchWrapper = {
    get: request('GET'),
    post: request('POST'),
    postForm: requestForm('POST'),
    put: request('PUT'),
    delete: request('DELETE')
};

export const fetchWrapperNotLogin = {
    get: requestNotLogin('GET'),
    post: requestNotLogin('POST'),
};

function request(method) {
    return (url, body) => {
        const requestOptions = {
            method,
            headers: authHeader(url)
        };
        if (body) {
            requestOptions.headers['Content-Type'] = 'application/json';
            requestOptions.body = JSON.stringify(body);
        }

        return fetch(url, requestOptions).then(handleResponse);
    }
}

function requestForm(method) {
    return (url, data) => {
    console.log(data);
        const requestOptions = {
            method,
            headers: authHeader(url),
            body: JSON.stringify( { image: data } )
        };
        requestOptions.headers['Content-Type'] = 'application/json';      

        return fetch(url, requestOptions).then(handleResponse);
    }
}

function requestNotLogin(method) {
    return (url, body) => {
        const requestOptions = {
            method,
        };
        if (body) {
            requestOptions.headers = {};
            requestOptions.headers['Content-Type'] = 'application/json';
            requestOptions.body = JSON.stringify(body);
        }
            return fetch(url, requestOptions).then(handleResponse);
    }
}

// helper functions

function authHeader(url) {
    // return auth header with jwt if user is logged in and request is to the api url
    const { user } = useAuthStore();
    const isLoggedIn = !!user?.access_token;   

    const isApiUrl = url.startsWith(process.env.VUE_APP_API_BASE);
    if (isLoggedIn && isApiUrl) {
        return { Authorization: `Bearer ${user.access_token}` };
    } else {
        return {};
    }
}

function handleResponse(response) {
        return response.text().then(text => {
            let dataReceived = JSON.parse(text);
            Object.assign( dataReceived, {code: response.status} );
            const dataToSend = JSON.stringify(dataReceived);
            if (!response.ok) {                
                const { user, logout } = useAuthStore();
                if ([401, 403].includes(response.status) && user) {
                    logout();
                }

                if ([400, 422, 429, 500].includes(response.status)) {
                    return Promise.reject( dataToSend );
                }

                return Promise.reject( dataToSend );
            }

            return dataReceived;
        });
}