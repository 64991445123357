import { defineStore } from 'pinia'
import { fetchWrapperNotLogin } from '@/helpers';
import { fetchWrapper } from '@/helpers';
const getPlansRoute = process.env.VUE_APP_API_BASE + '/tpv/getPlans';
const createSession = process.env.VUE_APP_API_BASE + '/payments/session/';
const finishSession = process.env.VUE_APP_API_BASE + '/payments/finish_session/';
const acceptPrice   = process.env.VUE_APP_API_BASE + '/tpv/accept/';

export const useTiendaStore = defineStore('tienda',{
    state: () => ({
        prices: {},
        actualPrice: "",
        code: ""
    }),
    actions: {
        async cargarPlanes() {
            try {
                const response  = await fetchWrapperNotLogin.get( getPlansRoute );
                console.log(response.data);
                return response.data;
            } catch (error) {
                console.error(error);
                return false;
            }
        },
        async createSession( price, code ) {
            let response   = await fetchWrapper.post( createSession + price, { code: code } );
            return response.data;
        },
        async accept( price ) {
            let response   = await fetchWrapper.post( acceptPrice + price, {} );
            console.log(response);
            return response.data;
        },
        async endSession( session_id ) {
            let response   = await fetchWrapper.post( finishSession + session_id, {} );
            return response;
        }
    }     
})