import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { manageErrors } from '@/helpers/errors';

const baseUrl               = process.env.VUE_APP_API_BASE + "/user" ;
const updateUser            = process.env.VUE_APP_API_BASE + '/updateUser';
const subs                  = process.env.VUE_APP_API_BASE + "/tpv"

export const useUser = defineStore({
    id: 'user',
    state: () => ({
        ok:"",
        message:"",
        mysubsdata:{},
        cancelled: false
    }),
    actions: {

        async hasAvailableLaps(){
            let objeto = this
            await fetchWrapper.get(`${baseUrl}` + '/hasAvailableLaps').then( function(response) {
                objeto.ok           = response.ok;
                objeto.messageError = response.message;
            }).catch( function(error){ manageErrors( error ) });
        },

        async updateUser(data){
            let objeto = this
            await fetchWrapper.post( updateUser, data ).then( function(response) {
                objeto.ok       = response.ok
                objeto.message  = response.message
            }).catch( function(error){ manageErrors( error ) });
        },
        async updateImge(data){
            let objeto = this
            await fetchWrapper.postForm( `${baseUrl}` + '/uploadImage'  , data ).then( function(response) {                
                objeto.ok       = response.ok
                objeto.message  = response.message
            }).catch( function(error){ manageErrors( error ) });
        },
        async mysub(){
            let objeto = this
            await fetchWrapper.get( `${subs}` + '/getSubscription' ).then( function(response) {   
                objeto.mysubsdata     = response.data             
                objeto.ok             = response.ok
                objeto.message        = response.message
            }).catch( function(error){ manageErrors( error ) });
        }

    }
});