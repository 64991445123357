import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { useAuthStore } from '@/store/auth.store'
import { storeToRefs } from 'pinia'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/tienda',
    name: 'tienda',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "tienda" */ '../views/TiendaView.vue')
  },
  {
    path: '/registro',
    name: 'registro',
    component: () => import(/* webpackChunkName: "registro" */ '../views/RegistroView.vue')
  },
  {
    path: '/registrar-vuelta',
    name: 'registrar-vuelta',
    component: () => import(/* webpackChunkName: "registrar-vuelta" */ '../views/RegistroVueltaView.vue')
  },
  {
    path: '/registrar-vuelta-invitacion/:idVuelta',
    name: 'registrar-vuelta-invitacion',
    component: () => import(/* webpackChunkName: "registrar-vuelta" */ '../views/RegistroVueltaInvitacionView.vue')
  },
  {
    path: '/registrar-vuelta-t',
    name: 'registrar-vuelta-t',
    component: () => import(/* webpackChunkName: "registrar-vuelta" */ '../views/RegistroVueltaTView.vue')
  },
  {
    path: '/vuelta/:idVuelta',
    name: 'vuelta',
    component: () => import(/* webpackChunkName: "vuelta" */ '../views/VueltaView.vue')
  },
  {
    path: '/perfil',
    name: 'perfil',
    component: () => import(/* webpackChunkName: "perfil" */ '../views/PerfilView.vue')
  },
  {
    path: '/estadisticas',
    name: 'estadisticas',
    component: () => import(/* webpackChunkName: "estadisticas" */ '../views/EstadisticasView.vue')
  },
  {
    path: '/estadisticas-t',
    name: 'estadisticas-t',
    component: () => import(/* webpackChunkName: "estadisticas" */ '../views/EstadisticasTView.vue')
  },
  {
    path: '/activation',
    name: 'activation',
    component: () => import(/* webpackChunkName: "activation" */ '../views/ActivationView.vue')
  },
  {
    path: '/pago-denegado',
    name: 'pago-denegado',
    component: () => import(/* webpackChunkName: "denegado" */ '../views/PagoDenegadoView.vue')
  },
  {
    path: '/recuperar_contrasena',
    name: 'recuperar_contrasena',
    component: () => import( '../views/RecuperarContrasenaView.vue')
  },
  {
    path: '/compra-correcta',
    name: 'compra-correcta',
    component: () => import(/* webpackChunkName: "activation" */ '../views/CompraCorrectaView.vue')
  },
  {
    path: '/compra-incorrecta',
    name: 'compra-incorrecta',
    component: () => import(/* webpackChunkName: "activation" */ '../views/CompraIncorrectaView.vue')
  },
  {
    path: '/comunidad',
    name: 'comunidad',
    component: () => import(/* webpackChunkName: "comunidad" */ '../views/ComunidadView.vue')
  },
  {
    path: '/comunidad-t',
    name: 'comunidad-t',
    component: () => import(/* webpackChunkName: "comunidad" */ '../views/ComunidadTView.vue')
  },
  {
    path: '/agregar-amigos',
    name: 'agregar-amigos',
    component: () => import(/* webpackChunkName: "activation" */ '../views/AgregarAmigosView.vue')
  },
  {
    path: '/historico-vueltas/:id?',
    name: 'historico-vueltas',
    component: () => import(/* webpackChunkName: "activation" */ '../views/HistoricoVueltasView.vue')
  },
  {
    path: '/vueltas-t',
    name: 'historico-vueltas-t',
    component: () => import(/* webpackChunkName: "activation" */ '../views/HistoricoVueltasTView.vue')
  },
  {
    path: '/resumen-vueltas',
    name: 'resumen-vueltas',
    component: () => import(/* webpackChunkName: "resumenVuelta" */ '../views/ResumenVueltaView.vue')
  },
  {
    path: '/editar-usuario',
    name: 'editar-usuario',
    component: () => import(/* webpackChunkName: "resumenHoyo" */ '../views/EditarUsuarioView.vue')
  },
  {
    path: '/partida-finalizada',
    name: 'partida-finalizada',
    component: () => import(/* webpackChunkName: "PartidaFinalizada" */ '../views/PartidaFinalizadaView.vue')
  },
  {
    path: '/resumen-hoyo',
    name: 'resumen-hoyo',
    component: () => import(/* webpackChunkName: "resumenHoyo" */ '../views/ResumenHoyoView.vue')
  },
  {
    path: '/aviso-legal',
    name: 'aviso-legal',
    component: () => import(/* webpackChunkName: "avisoLegal" */ '../views/AvisoLegalView.vue')
  },
  {
    path: '/politica-privacidad',
    name: 'politica-privacidad',
    component: () => import(/* webpackChunkName: "politicaPrivacidad" */ '../views/PoliticaPrivacidadView.vue')
  },
  {
    path: '/cookies',
    name: 'cookies',
    component: () => import(/* webpackChunkName: "cookies" */ '../views/CookiesView.vue')
  },
  {
    path: '/nuestra-mision',
    name: 'nuestra-mision',
    component: () => import(/* webpackChunkName: "cookies" */ '../views/NuestraMisionView.vue')
  },  
  {
    path: '/contacto',
    name: 'contacto',
    component: () => import(/* webpackChunkName: "contacto" */ '../views/ContactoView.vue')
  },
  {
    path: '/golpe-maqueta',
    name: 'golpe-maqueta',
    component: () => import(/* webpackChunkName: "golpe-maqueta" */ '../views/VueltaMaquetaView')
  },
  {
    path: '/join/:id',
    name: 'join',
    component: () => import(/* webpackChunkName: "join" */ '../views/JoinView.vue')
  },
  {
    path: '/vueltas-pendientes',
    name: 'vueltas-pendientes',
    component: () => import(/* webpackChunkName: "join" */ '../views/VueltasPendientesView.vue')
  },
  {
    path: '/estadisticas-friend/:idFriend',
    name: 'estadisticas-friend',
    component: () => import(/* webpackChunkName: "estadisticasFriend" */ '../views/EstadisticasGeneralesAmigos.vue')
  },
  {
    path: '/condiciones-generales',
    name: 'condiciones-generales',
    component: () => import(/* webpackChunkName: "estadisticasFriend" */ '../views/CondicionesGeneralesContratacion.vue')
  }



  

  
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});
export default router




router.beforeEach(async (to) => {
  const auth = useAuthStore();
  auth.checkSession();
  const { isUserLoggedIn} = storeToRefs(auth);
  const publicPages = ['/' ,'/tienda', '/registro', '/recuperar_contrasena','/activation','/aviso-legal','/politica-privacidad','/cookies', '/condiciones-generales', '/nuestra-mision', '/join', '/contacto','/comunidad-t', '/estadisticas-t', '/vueltas-t', '/registrar-vuelta-t'];

  // redirect to login page if not logged in and trying to access a restricted page
  var authRequired = ( to.name == 'join' ) ? false : !publicPages.includes(to.path)
  if (authRequired && !isUserLoggedIn.value) {
      return '/';
  }
});
