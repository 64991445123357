<template>
  <header class="bg-gray-900 lg:pt-14 pt-8 fondo-header">
    <div class="container mx-auto lg:px-4 px-6 pb-8">
      <div class="flex justify-between items-start">
        <div class="cont-logo">
          <router-link to="/" @click="closeMenu">
            <img src="@/assets/img/logo.png" alt="TRF" class="w-auto" :class="{'lg:h-24 h-20':!isHomePage, 'lg:h-36 h-20':isHomePage}"
            />
          </router-link>
        </div>

        <div class="lg:hidden flex" >
          <router-link to="/perfil" class="z-10" v-if="isUserLoggedIn">
            <img v-if="userData.thumbnail"
              :src="ulr + userData.thumbnail"
              alt="Foto de perfil"
              class="h-7 w-7 rounded-full"
            />
            <img v-else-if="thumbnail"
              :src="ulr + thumbnail"
              alt="Foto de perfil"
              class="h-7 w-7 rounded-full"
            />
            <img v-else
              alt="Foto"
              class="h-7 w-7 rounded-full"
            />
          </router-link>

          <img
            @click="toggleMobileMenu"
            src="@/assets/img/hamburguer.png" 
            alt="Menú"
            class="h-6 cursor-pointer toggle-menu ml-3"
          />
        </div>        

        <nav class="space-x-6 items-center" :class="{ 'hidden lg:flex': !isMobileMenuOpen, 'nav-movil': isMobileMenuOpen }">
          <router-link @click="closeMenu" to="/">Home</router-link>
          <router-link @click="closeMenu" to="/tienda">Tienda</router-link>

          <template v-if="!isUserLoggedIn">
            <router-link @click="closeMenu" to="/estadisticas-t">Estadisticas</router-link>
            <router-link @click="closeMenu" to="/vueltas-t">Vueltas</router-link>
            <router-link @click="closeMenu" to="/comunidad-t">Comunidad</router-link>
            <div class="link-registra-vuelta-logged"  @click="() => { closeMenu(); goTo('/registrar-vuelta-t'); }">Registrar vuelta</div>
            <a @click="() => { closeMenu(); openPopUp(); }" class="link-login">Iniciar sesión</a>
          </template>

          <template v-else>
            <router-link @click="closeMenu" to="/estadisticas">Estadisticas</router-link>
            <router-link @click="closeMenu" :to="{ name: 'historico-vueltas', params: { id: '' }, force: Date.now() }">Vueltas</router-link>
            <router-link @click="closeMenu" to="/comunidad">Comunidad</router-link>
            <div class="link-registra-vuelta-logged"  @click="() => { closeMenu(); combrobarVueltasDispo(); }">Registrar vuelta</div>
            
            <div @click="() => { closeMenu(); toggleVerMas(); }" class="ver-mas cursor-pointer hidden lg:flex">
              <img v-if="userData.thumbnail" :src="ulr + userData.thumbnail" alt="Foto de perfil" class="h-10 w-10 rounded-full" />
              <img v-else-if="thumbnail" :src="ulr + thumbnail" alt="Foto de perfil" class="h-10 w-10 rounded-full" />
              <img v-else alt="Foto" class="h-10 w-10 rounded-full" />
            </div> 

            <div @click="() => { closeMenu(); toggleVerMas(); }" class="ver-mas cursor-pointer hidden lg:flex">
              <span class="text-white" v-if="userData.name">{{userData.name}}</span>
              <span class="text-white" v-else>{{name}}</span>
              <div :class="{ 'hidden': !isVerMasOpen }">
                <router-link @click="closeMenu" to="/perfil">Ver Perfil</router-link>
                <span @click="auth.logout()"><router-link to="/">Cerrar Sesion</router-link></span>
              </div>
            </div>

            <div class="lg:hidden flex">
              <router-link @click="closeMenu" to="/perfil">Ver Perfil</router-link>
            </div>
            <div class="lg:hidden flex">
              <span @click="auth.logout()"><router-link to="/">Cerrar Sesion</router-link></span>
            </div>
          </template>
        </nav>
      </div>
    </div>
    <div v-if="isHomePage" class="caja-cita container mx-auto lg:px-4 px-6">
      <div class="cita">
        "Golf is the only game I know of that actually becomes harder the longer you play it."
      </div>
      <div class="autor-cita">
        BOBBY JONES
      </div>
    </div>
  </header>
  

</template>

<script setup>
  import { ref, computed } from "vue";
  import { useRoute } from "vue-router";
  import { usePopupStore } from "@/store/popup";  
  import { useAuthStore } from '@/store/auth.store';
  import { storeToRefs } from "pinia";
  import { useUser } from '@/store/user.store'; 
  import router from '@/router';

  const msg = usePopupStore();
  const { mensaje } = storeToRefs(msg); 
  
  const request = useUser();

  const ulr = process.env.VUE_APP_BASE

  function combrobarVueltasDispo(){
    
    const { ok } = storeToRefs(request);

    request.hasAvailableLaps().then(() =>{
        if(ok.value){
            router.push('/registrar-vuelta');
        }
    }).catch(function(error){const errorObject =  JSON.parse(error) 
            console.log(errorObject) 
            mensaje.value = errorObject.message
            if(route.path == "/"){
              msg.toggleInfo(true);
            }else{
            router.push('/?infomodal=ok');
            }
    })
  }

  function goTo( route ) {
    router.push( route );
  }

  // Propiedad computada para determinar si estamos en la página de inicio o no
  const route = useRoute();
  const isHomePage = computed(() => route.path === "/");

  const auth = useAuthStore();
  const { isUserLoggedIn ,  userData  } = storeToRefs(auth);
  const userdata = localStorage.getItem("userData")
  let name 
  const thumbnail = ref("")
  if (userdata) {
    const userDataParse = JSON.parse(userdata)
    if(userDataParse.name != null){
      name = userDataParse.name
    }
    if(userDataParse.thumbnail != null){
      thumbnail.value = userDataParse.thumbnail
    }
  }


  // Toggle mostrar ocultar menu movil
  const isMobileMenuOpen = ref(false);
  const toggleMobileMenu = () => {
    isMobileMenuOpen.value = !isMobileMenuOpen.value
  };
  
  const openPopUp = () => {
    usePopupStore().togglePopup(true)
  }
  
  const isVerMasOpen = ref(false);
  const toggleVerMas = () => {
      isVerMasOpen.value = !isVerMasOpen.value;
  };

  const closeMenu = () => {
    isMobileMenuOpen.value = false;
  };

</script>

<style scoped>

    .ver-mas>div{
        position: absolute;
        top: 60px;
        width: 175px;
        right: 0px;
    }

    .ver-mas a{
        font-family: Brandon;
        font-size: 15px;
        font-weight: 420;
        line-height: normal;
        border: 1px solid;
        padding: 10px 15px;
        margin-bottom: 10px;
        @apply rounded text-gris w-full block;
        background-color: white;
    }
    

</style>
  