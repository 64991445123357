import { defineStore } from 'pinia'
import { fetchWrapper } from '@/helpers'
import { usePopupStore } from "@/store/popup"
import { useHistoricVuelta } from '../store/historicovueltas.store'
import { storeToRefs } from 'pinia'
import { manageErrors } from '@/helpers/errors'
import router from '@/router'


const crearVuelta               = process.env.VUE_APP_API_BASE + '/vuelta/create'
const crearVueltaInvitacion     = process.env.VUE_APP_API_BASE + '/vuelta/createInvite'
const declineVueltaInvitacion   = process.env.VUE_APP_API_BASE + '/vuelta/declineInvitation'
const consultaVuelta            = process.env.VUE_APP_API_BASE + '/vuelta/getJugada'
const registrarGolpe            = process.env.VUE_APP_API_BASE + '/vuelta/registerStep'
const finishVuelta              = process.env.VUE_APP_API_BASE + '/vuelta/finish'
const vuelta                    = process.env.VUE_APP_API_BASE + '/vuelta'

export const useVueltaStore = defineStore('vuelta', {

    state: () => ({
        idJoin: '',
        green: false,
        vueltaActual: '',
        hoyoActual: '',
        vueltaCreada: '',
        idVuelta: '',
        numero_hoyos: '',
        hoyos_jugados: [],
        hoyos_select: [],
        golpes:[],
        ultimoGolpe:
            {
                numHoyo:-1,
                numGolpe:1,
                surface: 'TEE',
                meters:'',
                par:-1,
                incidence:'-',
                embocada: 0,
                levantar: 0,                
                green: false,
                selectedMedida: ''
            }
        ,
        golpeActual: 0,
        modificado: false,
        errorValidacion:{},
        fails:[],
        popupIncidencia:false,
        arrayGolpes:[],
        tipo_juego: "",
        id:"",
        pending:false
    }),
    actions: {

        reset() {
            this.ultimoGolpe.numHoyo    = -1
            this.ultimoGolpe.numGolpe   = 1
            this.ultimoGolpe.surface    = 'TEE'
            this.ultimoGolpe.meters     = ''
            this.ultimoGolpe.par        = -1
            this.ultimoGolpe.incidence  = '-'
            this.ultimoGolpe.embocada   = 0
            this.ultimoGolpe.levantar   = 0,               
            this.ultimoGolpe.green      = false
            this.ultimoGolpe.selectedMedida = ''
        },
        async crearVuelta(datos, invitation = false , id = false){
            let objeto = this
            await fetchWrapper.post((invitation == false) ? crearVuelta : crearVueltaInvitacion + '/' + id, datos).then( function(response) {
                objeto.reset()
                objeto.idVuelta         = response.data.idVuelta
                objeto.numero_hoyos     = response.data.num_holes
                objeto.fails            = []
                // cerramos ventana
                usePopupStore().togglePopupVueltaStart(false)
                // Redireccion
                router.push('/vuelta/' + objeto.idVuelta)
                objeto.pending = false
            }).catch( function(error){ manageErrors( error ) , objeto.pending = false});            
        },
        async declineInvitation(id){
            await fetchWrapper.post(declineVueltaInvitacion + '/' + id, {}).then( function() {
                // Recargamos vueltas pendientes
                const historicStore = useHistoricVuelta()
                historicStore.vueltas_pendientes()
            }).catch( function(error){ manageErrors( error ) });            
        },
        async consultaVuelta(id){
            let objeto = this
            await fetchWrapper.get(consultaVuelta + '/' + id).then( function(response) { 
                objeto.reset() 
                objeto.vueltaActual         = response.data
                objeto.tipo_juego           = objeto.vueltaActual.type
                objeto.numero_hoyos         = objeto.vueltaActual.num_holes
                let devuelta                = objeto.calcularHoyo(objeto.vueltaActual.data.golpes)[0]
                // Miramos superficie
                objeto.ultimoGolpe.numHoyo  = devuelta.hoyoActual
                objeto.ultimoGolpe.numGolpe = devuelta.golpeActual
                objeto.golpeActual          = devuelta.golpeActual
                // Reset modificado
                objeto.modificado = false          
                /////////////////////////
                objeto.ultimoGolpe.par      = devuelta.parActual
                objeto.calcularSelect(objeto.vueltaActual.data.golpes)
                devuelta.green === 1 ? objeto.green=true : objeto.green=false
                // Rellenamos el array de golpes
                objeto.golpes               = response.data.data.golpes
                objeto.arrayGolpes          = response.data.data.golpes[ response.data.data.golpes.length-1 ]
                if ( objeto.numero_hoyos === objeto.hoyos_jugados.length){
                    router.push('/historico-vueltas')
                }
            }).catch( function(error){ manageErrors( error ) }); 
        },

        async mandarGolpe(){                             
            if(this.validarGolpe()){
                let objeto = this;
                let infoGolpe       = Object.assign({}, this.ultimoGolpe)
                infoGolpe.numHoyo   = this.ultimoGolpe.numHoyo
                infoGolpe.numGolpe  = this.ultimoGolpe.numGolpe-1             
                if ( objeto.modificado == true ) {
                    infoGolpe.modified  = objeto.modificado
                }
                this.ultimoGolpe.green === true ? infoGolpe.green=1 : infoGolpe.green=0 
                
                await fetchWrapper.post(registrarGolpe + '/' + objeto.vueltaActual._id, infoGolpe).then( function( response ) {
                    if (response.ok) {
                        objeto.golpes   = response.data.golpes
                        if (objeto.ultimoGolpe.embocada === 1 || objeto.ultimoGolpe.incidence === 'EMBOCAR' || objeto.ultimoGolpe.levantar === 1 ) {      
                            objeto.ultimoGolpe.numHoyo  = ( objeto.ultimoGolpe.numHoyo === 18 ) ?  1 : (objeto.ultimoGolpe.numHoyo + 1);                        
                            objeto.ultimoGolpe.numGolpe = 1     
                            objeto.ultimoGolpe.par = -1                  
                            objeto.ultimoGolpe.incidence = "-"                        
                            objeto.ultimoGolpe.meters = ''
                            objeto.ultimoGolpe.surface = "TEE"                        
                            objeto.ultimoGolpe.embocada = 0
                            objeto.green=false
                            objeto.arrayGolpes = response.data.golpes[response.data.golpes.length-1];
                            router.push("/resumen-hoyo")
                        }else{
                            const numGolpe = response.data.golpes.length-1           
                            objeto.ultimoGolpe.numGolpe = response.data.golpes[numGolpe].length + 1
                            objeto.golpeActual = response.data.golpes[numGolpe].length + 1
                            objeto.ultimoGolpe.incidence = "-"
                            objeto.ultimoGolpe.meters = ''
                            objeto.arrayGolpes = response.data.golpes[response.data.golpes.length-1];
                            if ( numGolpe == 1 )
                                objeto.ultimoGolpe.surface = "TEE" 
                            else objeto.ultimoGolpe.surface = '-1'                        
                            infoGolpe.green === 1 ? objeto.green=true : objeto.green=false  
                        }                         
                        objeto.calcularSelect(response.data.golpes) 
                        objeto.ultimoGolpe.selectedMedida = ''  
                        // Reset modificado
                        objeto.modificado = false                     
                    } else {
                        console.error('Error response:', response.data.statusText);
                    }    
                }).catch(  function(error){ manageErrors( error ) } )
            }   
        },

        async crearGolpeNormal(){
            try {
                this.mandarGolpe()
            } catch (error) {
                console.log(error)
                this.fails = JSON.parse(error).errors
            }
        },      

        async crearGolpeIncidencia(incidencia){
            try {
                this.ultimoGolpe.incidence = incidencia
                this.mandarGolpe()
                this.toggleIncidence(false)
            } catch (error) {
                console.log(error)
                this.fails = JSON.parse(error).errors
            }
        },    

        async crearGolpeGreen(){
            try {
               this.ultimoGolpe.green = true
               this.mandarGolpe()
            } catch (error) {
                console.log(error)
                this.fails = JSON.parse(error).errors
            }
        },

        //Golpe desde el green
        async crearPutt(){
            try {
                this.ultimoGolpe.surface = "GREEN"
                this.ultimoGolpe.green = true
                this.mandarGolpe()
            } catch (error) {
                console.log(error)
                this.fails = JSON.parse(error).errors
            }
        },

        async crearGolpeFueraGreen(){
            try {
                this.ultimoGolpe.surface = "GREEN"
                this.ultimoGolpe.green = false
                this.mandarGolpe()
            } catch (error) {
                console.log(error)
                this.fails = JSON.parse(error).errors
            }
        },  

        async levantarBola(){
            this.ultimoGolpe.levantar = 1
            this.ultimoGolpe.green = false 

            let infoGolpe       = Object.assign({}, this.ultimoGolpe)
            infoGolpe.numHoyo   = this.ultimoGolpe.numHoyo
            infoGolpe.numGolpe  = this.ultimoGolpe.numGolpe-1  
            infoGolpe.surface   = "/"
            infoGolpe.meters    = 0

            this.ultimoGolpe.green === true ? infoGolpe.green=1 : infoGolpe.green=0 

            let objeto = this            
            await fetchWrapper.post(registrarGolpe + '/' + this.vueltaActual._id, infoGolpe).then( function(response) {
                if (response.ok){
                    objeto.ultimoGolpe.numGolpe     = 1     
                    objeto.ultimoGolpe.par          = -1                  
                    objeto.ultimoGolpe.incidence    = "-"                        
                    objeto.ultimoGolpe.meters       = ''
                    objeto.ultimoGolpe.surface      = -1                        
                    objeto.ultimoGolpe.embocada     = 0
                    objeto.ultimoGolpe.levantar     = 0
                    objeto.green                    = false
                    objeto.arrayGolpes              = response.data.golpes[response.data.golpes.length-1]
                    objeto.calcularSelect(response.data.golpes)
                    router.push("/resumen-hoyo")
                }
            }).catch(  function(error){ manageErrors( error ) } )
        },

        async pausarVuelta(){
            try {
                this.green=false
            } catch (error) {
                console.log(error)
                this.fails = JSON.parse(error).errors
            }
        },

        async embocado(){
            try {
                this.ultimoGolpe.embocada = 1    
                this.ultimoGolpe.surface = "GREEN" 
                this.ultimoGolpe.green = false           
                this.mandarGolpe()
            } catch (error) {
                console.log(error)
                this.fails = JSON.parse(error).errors   
            }
        },

        async finalizarVuelta(){
            await fetchWrapper.post(finishVuelta + '/' + this.vueltaActual._id).then( function() {
                router.push("/partida-finalizada")
            }).catch(  function(error){ manageErrors( error ) } )
        },

        async joinVuelta( id ){
            let objeto = this
            console.log(objeto)
            await fetchWrapper.post(vuelta + '/join/' + id).then( function(response) {
                if(response.ok == true) {
                    router.push( "/registrar-vuelta-invitacion/" + response.data.idVuelta )                    
                }
            }).catch(  function(error){ manageErrors( error ) } )
        },

        async vueltas_pendientes() {
            let objeto                  = this
            console.log(objeto)
            const popupStore            = usePopupStore()
            const { mensaje }           = storeToRefs( popupStore )            
            await fetchWrapper.get(vuelta + '/getPending' ).then( function(response) {
                if( typeof response.data.data != "undefined" && response.data.data.length !== 0 ){
                    // Tienes vueltas pendientes
                    mensaje.value = 'Tienes vueltas pendientes de aceptar. Las podrás encontrar en tu perfil, en la sección Vueltas pendientes.'
                    popupStore.toggleInfo(true)                   
                }
            }
            ).catch(  function(error){ manageErrors( error ) } )      
        },  

        validarGolpe(){
            this.fails = {
                meters:[],
                par:[],
                surface:[],
                numGolpe:[],
                numHoyo:[],
                incidence:[],
            }

            let fallo=false;

            if (!this.ultimoGolpe.numHoyo) {
                this.fails.numHoyo[0] = 'El campo Hoyo es obligatorio'
                fallo=true;
            } else if (!Number.isInteger(this.ultimoGolpe.numHoyo)) {
                this.fails.numHoyo[0] = 'El campo Hoyo tiene que ser numérico'
                fallo=true;
            } else if ( this.ultimoGolpe.numHoyo == -1 ) {
                this.fails.numHoyo[0] = 'El campo Hoyo tiene que ser numérico'
                fallo=true;
            }

            if (!this.ultimoGolpe.numGolpe) {
                this.fails.numGolpe[0] = 'El campo Golpe es obligatorio'
                fallo=true;
            } else if (!Number.isInteger(this.ultimoGolpe.numGolpe)) {
                this.fails.numGolpe[0] = 'El campo Golpe tiene que ser numérico'
                fallo=true;
            }
        
            if (!this.ultimoGolpe.surface) {
                this.fails.surface[0] = 'El campo Superficie es obligatorio'
                fallo=true;
            }
            if ( this.ultimoGolpe.selectedMedida != 'otro' && this.ultimoGolpe.selectedMedida != '' ) {
                this.ultimoGolpe.meters = this.ultimoGolpe.selectedMedida
            }
            if (!this.ultimoGolpe.meters) {
                this.fails.meters[0] = 'El campo Metros es obligatorio'
                fallo=true;
            }else if (parseFloat(this.ultimoGolpe.meters)=="NaN") {
                this.fails.meters[0] = 'El campo Metros tiene que ser numérico'
                fallo=true;
            } else if (this.ultimoGolpe.meters < 0) {
                if ( this.ultimoGolpe.meters != -30 && this.ultimoGolpe.meters != -4){
                    this.fails.meters[0]= 'El campo Metros no puede ser un número negativo excepto -30 o -4'
                    fallo=true;
                }
            }
            console.log(this.ultimoGolpe.par);
            if (!this.ultimoGolpe.par) {
                this.fails.par[0] = 'El campo Par es obligatorio'
                fallo=true;
            }else if (!Number.isInteger(parseInt(this.ultimoGolpe.par))) {                
                this.fails.par[0] = 'El campo Par tiene que ser numérico'
                fallo=true;
            } else if (this.ultimoGolpe.par < 0) {
                this.fails.par[0] = 'El campo Par no puede ser un número negativo'
                fallo=true;
            }
        
            if (!this.ultimoGolpe.incidence || typeof this.ultimoGolpe.incidence !== 'string') {
                this.fails.incidence[0] = 'El campo Incidence es obligatorio'
                fallo=true;
            }

            console.log(this.fails);
            return !fallo;

        },

        calcularSelect(golpes){
            const hoyosJugados = [];
            const hoyosDsiponibles=[];
            let hoyoActual=0;
            for (const golpesHoyo of golpes) {
                for (const golpe of golpesHoyo) {
                    if (!hoyosJugados.includes(golpe.numHoyo)) {
                        if ( golpe.conversion === true || golpe.levantar === true ){
                         hoyosJugados.push(golpe.numHoyo);
                        }
                        hoyoActual=golpe.numHoyo;
                    }
                }
            }
            for (let i = 1; i < 19; i++) {
             
                if (!hoyosJugados.includes(i)) {
                    hoyosDsiponibles.push(i);
                }
            }
            console.log(hoyoActual)
            this.hoyos_jugados=hoyosJugados;
            this.hoyos_select=hoyosDsiponibles;
            //this.ultimoGolpe.numHoyo=-1;
            //console.log("calcularSelect " + this.ultimoGolpe.numHoyo)
        },

        calcularHoyo(golpes){
            let datos = []
            let hoyoActual = ""
            let golpeActual = ""
            let parActual = ""
            let lastHoyoArray = ""
            let lastGolpeArray = ""
            let surfaceActual = ""
            let green = 0
        
            if (golpes.length === 0) {
                hoyoActual = -1
                golpeActual = 1        
                parActual = -1
                surfaceActual = 'TEE'
                green = lastGolpeArray.green    
            }else{
                lastHoyoArray = golpes[golpes.length-1]
                lastGolpeArray = lastHoyoArray[lastHoyoArray.length-1]
                console.log(lastGolpeArray)
                if ( lastGolpeArray.conversion === true || lastGolpeArray.levantar === true ){
                    hoyoActual = ( lastGolpeArray.numHoyo == 18 ) ? 1 : ( lastGolpeArray.numHoyo + 1 )
                    golpeActual = 1
                    parActual = -1
                    surfaceActual = "TEE"  
                }else{       
                    hoyoActual = golpes[golpes.length-1][0].numHoyo
                    console.log(hoyoActual)
                    golpeActual = lastHoyoArray.length+1
                    parActual = lastGolpeArray.par
                    surfaceActual = -1        
                }
                
                green = ( lastGolpeArray.green == 1 && lastGolpeArray.conversion == true )
                        ? false 
                        : lastGolpeArray.green
            }
            console.log(datos)
            this.ultimoGolpe.selectedMedida = ''
            datos.push({hoyoActual, golpeActual, parActual, surfaceActual, green})
            return datos
        },

        toggleIncidence(value){
            this.popupIncidencia=value
        }

    },

})

 