import { storeToRefs } from "pinia";
import { usePopupStore } from "@/store/popup";
import router from '@/router'

export const manageErrors = function( error, type = 'info' ) {
    const msg           = usePopupStore();
    const { mensaje }   = storeToRefs(msg);
    console.log(error)
    const errores = JSON.parse(error) 
    mensaje.value = ( typeof errores.message != 'undefined' ) ? errores.message : '';
    if ( mensaje.value == '' ) {
        const errorAccess = errores.errors;
        for (const [key, value] of Object.entries(errorAccess)) {
            console.log(key)
            mensaje.value = value[0]; 
        }
    }
    console.log(mensaje.value)
    if ( typeof errores.code != 'undefined' &&  
         ( errores.code == 400 || errores.code == 401 || errores.code == 429 || errores.code == 403 ) ) {
            if ( type != 'login' ) {
                router.push('/?infomodal=ok'); 
            }
    } else {
        if ( type == 'info' ) {
            msg.toggleInfo( true );
        } 
        if ( type == 'login' ) {
            msg.toggleRegistro( true );
        }        
    }    
}