import { usePopupStore } from "@/store/popup";  
import { storeToRefs } from "pinia";
import { useTiendaStore } from '@/store/tienda.store';

export const planTiendaClick = function( ) {    
    // Price
    const tiendaStore = useTiendaStore();
    const { actualPrice, code } = storeToRefs(tiendaStore);

    const popup = usePopupStore();
    popup.togglePopupCondiciones(false)
    
    // Petición
    useTiendaStore().createSession( actualPrice.value, code.value ).then( 
        function(result) { 
            // Peticion aceptar
            useTiendaStore().accept( actualPrice.value ).then( function() {
                // Redirigimos
                window.location = result.data.url;
            });             
        } ).catch( function(error) { 
            console.log(error);
            const errorMsg = JSON.parse( error );
            const popUp = usePopupStore();
            const { mensaje } = storeToRefs(popUp);
            mensaje.value = errorMsg.message;
            usePopupStore().toggleInfo(true)
        });
};

export const clickTiendaCondiciones = function( event ) {    

    
    
    const tiendaStore = useTiendaStore();
    const { actualPrice } = storeToRefs(tiendaStore);

    const popup = usePopupStore();
    popup.togglePopupCondiciones(true)
    actualPrice.value = event.currentTarget.getAttribute("price");
    
};