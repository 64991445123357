import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { manageErrors } from '@/helpers/errors';

const baseUrl               = process.env.VUE_APP_API_BASE + "/vuelta" ;



export const useHistoricVuelta = defineStore({
    id: 'historyVuelta',
    state: () => ({
        q:"",
        vueltasPendientes: [],
        historyVueltaArray:[],
        vuelta:{},
        hoyosArray:[],
        estadisticas:[],
        page:"1",
        vueltaDatos: {},
        idVuelta: ''
    }),
    actions: {

        async historyVuelta( id = null ){
            let objeto = this
            if ( objeto.page == 1 )
                objeto.historyVueltaArray = [];
            // Datos a enviar
            var Data = { sort_name:"date_vuelta" , sort_dir:"DESC",perPage:"10",page:objeto.page, q: objeto.q };
            var Url  = `${baseUrl}` + '/history'; 
            if ( id != null ) {
                Url += '/' + id;
            }
            if ( Url.charAt(Url.length - 1) == '/' ) {
                Url = Url.slice(0, -1);
            }
            console.log(Url);
            await fetchWrapper.post(Url , Data).then( function(response) {
                if(objeto.historyVueltaArray.length > 0 && objeto.page > 1 ){
                    if(response.data.length > 0){
                        for (let i = 0; i < response.data.length; i++) {
                            objeto.historyVueltaArray.push(response.data[i]) 
                        } 
                    } 
                } else {
                    objeto.historyVueltaArray = response.data;
                }
                
            }).catch( function(error){ manageErrors( error ) });            
        },

        async vueltas_pendientes(){
            let objeto = this      
            objeto.vueltasPendientes = []    
            await fetchWrapper.get(`${baseUrl}` + '/getVueltasPendientes').then( function(response) {
                for (let i = 0; i < response.data.length; i++) {
                    objeto.vueltasPendientes.push(response.data[i]) 
                }                
            }).catch( function(error){ manageErrors( error ) });            
        },

        async filterVueltaHistory( id = null ){
            let objeto = this 
            if ( objeto.page == 1 )
                objeto.historyVueltaArray = []
            var Data = { sort_name:"date_vuelta" , sort_dir:"DESC",perPage:"10",page:objeto.page, q: objeto.q };
            var Url  = `${baseUrl}` + '/history';
            if ( id != null ) {
                Url += '/' + id;
            }
            if ( Url.charAt(Url.length - 1) == '/' ) {
                Url = Url.slice(0, -1);
            }
            await fetchWrapper.post(Url,Data ).then( function(response) {
                if(objeto.historyVueltaArray.length > 0 && objeto.page > 1 ){
                    if(response.data.length > 0){
                        for (let i = 0; i < response.data.length; i++) {
                            objeto.historyVueltaArray.push(response.data[i]) 
                        } 
                    }
                }else{
                  objeto.historyVueltaArray = response.data;  
                }
            }).catch( function(error){ manageErrors( error ) });            
        },
        async vueltaData(id, idFriend = null){
            this.hoyosArray = [];
            let Url = `${baseUrl}` + '/get/'+id;
            if ( idFriend != null ) {
                Url += '/'+ idFriend;
            }
            if ( Url.charAt(Url.length - 1) == '/' ) {
                Url = Url.slice(0, -1);
            }
            const response = await fetchWrapper.get( Url )
            this.vuelta               = response.data; 
            this.estadisticas         = response.data.estadisticas; 
            this.hoyosArray           = response.data.data.golpes
        },
        async vueltaDataOnly(){        
            const response = await fetchWrapper.get(`${baseUrl}` + '/getData/' + this.idVuelta)
            this.vueltaDatos      = response.data          
        }
    }
});