import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
//import { storeToRefs } from "pinia";
//import { usePopupStore } from "@/store/popup";
import { manageErrors } from '@/helpers/errors';
import router from '@/router'

const baseUrl               = process.env.VUE_APP_API_BASE ;

export const useAuthStore = defineStore({
    id: 'auth',

    state: () => ({
        // initialize state from local storage to enable user to stay logged in
        user: JSON.parse( localStorage.getItem('user')) || null,
        userData: {},
        returnUrl: null,
        isUserLoggedIn: false //Variable de control ed Login

    }),
    actions: {

        async login(email, password) {
            let objeto = this
            const user = {}
            await fetchWrapper.post(`${baseUrl}` + '/login', { email, password }).then( function(response) {
                if ( response.ok ){
                    user.date_start         = Math.round( new Date().getTime() / 1000 );
                    user.access_token       = response.data.access_token;
                    user.expires_in         = response.data.expires_in; 
                    objeto.userData         = {};
                    const userData          = response.data.userData;
                    objeto.userData         = userData;
                    objeto.user             = user;                    
                    objeto.isUserLoggedIn   = true;       
                    localStorage.setItem('user', JSON.stringify(user));
                    localStorage.setItem('userData', JSON.stringify(userData));
                }
            });                  
        },

        async logout() {                      
            this.isUserLoggedIn = false; 
            this.user = null;
            this.userData = {};
            localStorage.removeItem('user');
            localStorage.removeItem('userData');
            router.push('/');
        },

        async checkSession() {
            if ( localStorage.getItem('user') == null ) {                
                this.isUserLoggedIn = false; 
                return false;   
            }  
            const user = JSON.parse(localStorage.getItem('user'));
            // compatibilidad versiones anteriores
            if ( typeof user.date_start == 'undefined' ) {
                return false;
            }
            const DateIn        = user.date_start;
            const ExpiresIn     = user.expires_in;
            // cogemos fecha actual 
            const FechaActual   = Math.round( new Date().getTime() / 1000 );
            /////////////////////////////////////

            const isLogin = FechaActual < (DateIn + ExpiresIn);
            this.isUserLoggedIn = isLogin;
            return isLogin;
        },

        async me(){
            let objeto = this
            await fetchWrapper.get(`${baseUrl}` + '/me').then( function(response) {
                if(response.ok) {
                    objeto.userData = response.data;
                    localStorage.setItem('userData', JSON.stringify(objeto.userData));
                 }
            }).catch( function(error){ manageErrors( error ) });            
        }
    }
});
