import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { usePopupStore } from "@/store/popup";
import { manageErrors } from '@/helpers/errors';

const baseUrl               = process.env.VUE_APP_API_BASE ;

export const usePasswordStore = defineStore({
    id: 'pass',
    state: () => ({
        mail:"",
        u:"",
        t:"",
        user:"",
        token:"",
        message: "",
        password:"",
        password_confirmation:"",
        ok:false
    }),
    actions: {

        async forgotPassword(email){
            let objeto = this
            await fetchWrapper.post(`${baseUrl}` + '/forgetPassword',{email}).then( function(response) {
                objeto.message = response.message.toString()
                usePopupStore().toggleRegistro( false )             
            }).catch( function(error){ manageErrors( error ) });
        },

        async checkPasswordToken(u,t){
            let objeto = this
            await fetchWrapper.post(`${baseUrl}` + '/checkPasswordToken',{ u , t }).then( function(response) {
                objeto.message  = response.message
                objeto.ok       = response.ok
            }).catch( function(error){ manageErrors( error ) });
        },

        async changePassword(user,token,password,password_confirmation){
            let objeto = this
            await fetchWrapper.post(`${baseUrl}` + '/changePassword',{ user , token , password , password_confirmation }).then( function(response) {
                objeto.message = response.message
                objeto.ok = response.ok
            }).catch( function(error){ manageErrors( error ) });
        }
    }

});