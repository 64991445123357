import { defineStore } from 'pinia';

export const usePopupStore = defineStore('popup', {

    state: () => ({ 
        popupRegistroVisible: false,        
        infoModal: false,
        mensaje: "",
        popupPassword: false, 
        popupPasswordChange : false,
        popupVisible: false ,
        popupNotificaciones : false,
        popupDeleteFriend : false ,
        popupAddFriendsStatistics : false,
        noStats:false,
        popupUpdated : false,
        popupFinish : false,
        popupContact:false,
        popupLevantar: false,
        popupPausar: false,
        popupVueltaStart:false,
        popupVueltasPendientes: false,
        popupVueltaInviteStart: false,
        popupnoStatsFriend:false,
        popupCondiciones:false,
        popupRegistroDemoVisible: false
    }),
    actions: {

        togglePopup(value){
            this.popupVisible=value;
        },
        togglePopupLevantar(value){
            this.popupLevantar=value;
        },
        toggleRegistro(value){
            this.popupRegistroVisible=value;
        },
        toggleRegistroDemo(value){
            this.popupRegistroDemoVisible=value;
        },
        toggleInfo(value){
            this.infoModal=value;
        },
        togglePopupPassword(value){
            this.popupPassword=value;
        },
        togglePopupPasswordChange(value){
            this.popupPasswordChange=value;
        },
        togglePopupNotificaciones(value){
            this.popupNotificaciones=value;
        },
        togglePopupDeleteFriend(value){
            this.popupDeleteFriend=value;
        },
        togglePopupAddFriendsStatistics(value){
            this.popupAddFriendsStatistics=value;
        },
        togglenoStats(value){
            this.noStats=value;
        },
        togglePopupUpdated(value){
            this.popupUpdated=value;
        },
        togglePopupFinish(value){
            this.popupFinish=value;
        },
        togglePopupContact(value){
            this.popupContact=value;
        },
        togglePopupPausar(value){
            this.popupPausar=value;
        },
        togglePopupVueltaStart(value){
            this.popupVueltaStart=value;
        },
        togglePopupVueltaInviteStart(value){
            this.popupVueltaStart=value;
        },
        togglePopupVueltasPendientes(value){
            this.popupVueltasPendientes=value;
        },
        togglePopupnoStatsFriend(value){
            this.popupnoStatsFriend=value;
        },
        togglePopupCondiciones(value){
            this.popupCondiciones=value
        }     
    }
    
});