<template>
  <div v-if="!(accepted || rejected) && !config" class="cookie-bar">
    <div class="cookie-content flex flex-col lg:flex-row justify-between items-center">
      <p class="cookie-text">
        La presente página web utiliza cookies propias y de terceros para analizar tu navegación con fines estadísticos.<br>
        Para administrar o deshabilitar estas cookies haz click en “Configuración de Cookies”. Puedes consultar nuestra en <a href="/cookies">política de cookies</a>.<br>
        Pulsa el botón “Aceptar Cookies” para confirmar que has leído y aceptado la información presentada.
      </p>
      <div class="cookie-buttons pt-4 lg:pt-0">
        <button @click="setAnalyticsCookies()" class="boton-aceptar">Aceptar</button>
        <button @click="openConfig()" class="boton-rechazar">Configura Cookies</button>
        <button @click="rejectCookies()" class="boton-rechazar">Rechazar</button>
      </div>
    </div>
  </div>
  <div v-if="config && !(accepted || rejected)" class="cookie-bar">
    <div class="cookie-content flex flex-col sm:flex-row justify-between items-center">
      <p class="cookie-text">
        Analíticas y optimización: Cookies necesarias para optimizar la experiencia del usuario en la página web, evaluando su rendimiento y permitiendo mejorar nuestros servicios añadiendo nuevas funcionalidades. <br>
        Consulta nuestra <a href="/cookies">política de cookies</a> para más información.
      </p>
      <div class="cookie-buttons cookie-content-save">
            <label class="switch" >
                <input type="checkbox" v-model="isChecked">
                <span class="slider round"></span>
            </label>
            <button @click="comprobarConfig()" class="boton-guardar">Guardar Configuración</button>
        </div>

    </div>
  </div>
</template>
  
  <script setup>
      import { ref,onMounted } from 'vue';
      import VueCookies from 'vue-cookies';
      
      const accepted = ref(VueCookies.get('cookies-accepted'));
      const rejected = ref(VueCookies.get('cookies-rejected'));
      const isChecked = ref(false);
      

      const setAnalyticsCookies = () => {
        var analyticsScript = document.createElement('script');
            analyticsScript.async = true;
            analyticsScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-V6JXHNTFB0';
            document.head.appendChild(analyticsScript);
            // Segunda parte
            var script = document.createElement('script');
            script.innerHTML = `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-V6JXHNTFB0');
            `;
            document.head.appendChild(script);
            
            VueCookies.set('cookies-accepted', 'true', { sameSite: 'None' });
            accepted.value = true;
      };

      const config = ref(false);

      const openConfig = () => {
        config.value = true
      }

      const comprobarConfig = () => {
        if (isChecked.value == true) {
          setAnalyticsCookies()
        }else{
          rejectCookies()
        }
      }
      
      const rejectCookies = () => {
      // Elimina todas las cookies
        VueCookies.keys().forEach((cookie) => {
          VueCookies.remove(cookie);
        });
      
      // Establece la cookie de rechazo
        VueCookies.set('cookies-rejected', 'true');
        rejected.value = true;

      };

      
    onMounted(() => {
         if(accepted.value){
          setAnalyticsCookies();
         }
    });


  </script>
  
  
  <style scoped>
    .cookie-bar {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    color: #fff;
    padding: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    z-index: 9999;
    @apply bg-gris
    
    }
  
    .cookie-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .cookie-content-save{
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
    }

    @media (min-width: 640px) {
      .cookie-content-save {
        flex-direction: column; 
        align-items: center;
        justify-content: space-between;
        align-items: flex-end;
        }
    }
  
    .cookie-text {
        flex: 1; 
        text-align: left;
    }
    
    .cookie-buttons {
        display: flex;
    }
    .boton-guardar{
      margin-top: 10px;
    }
    
    button {
        margin-left: 10px; 
        background-color: #fff;
        color: #333;
        padding: 5px 10px;
        cursor: pointer;
    }

    .boton-aceptar{
        color: white;
        @apply bg-verde-claro bg-opacity-90 
    }

    a{
        @apply text-verde-claro
    }

    .switch {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 34px;
    }

    /* Hide default HTML checkbox */
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    /* The slider */
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }

    input:checked + .slider {
      @apply bg-verde-claro bg-opacity-90 
    }

    input:focus + .slider {
      box-shadow: #88A08A;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }

  </style>